<template>
<div>
  <van-empty
    description="页面找不到了"
    />
</div>
</template>

<script>
export default {
  name:'404'
}
</script>

<style scoped>

</style>
